// React
import { useEffect, useState } from "react";

// Interfaces
import { Marker } from "../interfaces/Marker";
import { RouteInfo } from "../interfaces/RouteInfo";
import { CapacityLimit } from "../interfaces/CapacityLimit";
import { Route } from "../interfaces/Directions";

// Components
import { Header } from "./Header";
import { OperationButtons } from "./OperationButtons";
import { Mapbox } from "./Mapbox";
import CodeMirror from "@uiw/react-codemirror";

// Mapbox
import mapboxgl from "mapbox-gl";
import { getMarkersFromApi } from "../services/getMarkers";

// Resources
import mapboxJson from "../resources/mapboxJson.json";
import emptyMapboxJson from "../resources/emptyMapboxJson.json";

// Graphics
import { json } from "@codemirror/lang-json";

// Utils
import * as operationUtils from "../utils/operationUtils";

const Operations = () => {
  /**Attributes */

  // Triggered when map is loaded
  const [mapLoaded, setMapLoaded] = useState(false);

  // Triggered when "Add using form" has been clicked
  const [showAddOperationForm, setshowAddOperationForm] = useState(false);

  // Json interface
  const [jsonInterface, setJsonInterface] = useState<string>(
    JSON.stringify(mapboxJson, undefined, 2)
  );

  // Initial & Final Lat - Lng that indicates startingPoint and endingPoint
  const [initialLatitudeLongitude, setinitialLatitudeLongitude] = useState([
    mapboxJson.StartingPoint.lng,
    mapboxJson.StartingPoint.lat,
  ]);

  const [finalLatitudeLongitude, setfinalLatitudeLongitude] = useState([
    mapboxJson.EndingPoint.lng,
    mapboxJson.EndingPoint.lat,
  ]);

  // Departure informations
  const [date, setDate] = useState(mapboxJson.StartingPoint.date);
  const [time, setTime] = useState(mapboxJson.StartingPoint.time);

  // Markers
  const [markers, setMarkers] = useState<Marker[]>(mapboxJson.Markers);
  const [markersObjects] = useState<Map<String, mapboxgl.Marker>>(new Map());
  const [uploadMarkerArray, setUploadMarkerArray] = useState<mapboxgl.Marker[]>(
    []
  );

  // Directions
  const [route, setRoute] = useState<Route[]>([]);

  // Api info
  const [isLoading, setIsLoading] = useState(false);
  const [deleteInfo, setDeleteInfo] = useState(false);
  const [err, setErr] = useState("");

    // Capacities calculates for every marker by API
    const [capacitiesArray, setCapacitiesArray] = useState<CapacityLimit[]>([]);

    // Info about trip received by mapbox APIs
    const [routeInfo, setRouteInfo] = useState<RouteInfo>();

    // Array of Starting capacities: [{id,name,quant}, ...]
    const [capacityLimit, setCapacityLimit] = useState<CapacityLimit[]>(
      mapboxJson.StartingPoint.capacityLimit
    );

  /**Functions  */

  // Update map with initial datas inside json textarea
  useEffect(() => {
    if (mapLoaded) {
      console.log("Map has finished loading, inserting all the markers..");
      parseMarkersFromJson(jsonInterface);
    }
  }, [mapLoaded]);

  // Update json textarea visualization
  useEffect(() => {
    console.log("Json Update based on markers");
    const updatedJsonInterface = JSON.parse(jsonInterface);
    updatedJsonInterface.Markers = markers;
    setJsonInterface(JSON.stringify(updatedJsonInterface, undefined, 2));
  }, [markers]);

  const handleClickStart = async (operationType) => {
    // Prepare params for API
    const stringifiedMarkers = JSON.stringify(markers);
    const stringifiedInitialLatitudeLongitude = JSON.stringify(
      initialLatitudeLongitude
    );
    const stringifiedFinalLatitudeLongitude = JSON.stringify(
      finalLatitudeLongitude
    );
    const stringifiedDeparture = JSON.stringify({
      depart_at: `${date}T${time}`,
    });

    const stringifiedCapacityLimit = JSON.stringify(capacityLimit);

    try {
      setIsLoading(true);
      console.log("Api call started");

      const markersFromApi = await getMarkersFromApi(
        stringifiedMarkers,
        operationType,
        stringifiedDeparture,
        stringifiedInitialLatitudeLongitude,
        stringifiedFinalLatitudeLongitude,
        stringifiedCapacityLimit
      ); // make api call

      setDeleteInfo(false); // show the info trip
      extractLatitudeLongitudeFromMarkers(markersFromApi.markersRoute); // get lat and lng for every point
      setRouteInfo(markersFromApi.routeInfo); // set info route
      setCapacitiesArray(markersFromApi.routeInfo.markersInfo.capacityLimit); // get all capacities for every single trip
    } catch (err: any) {
      setErr(err.message);
      alert(`No response from api ${err}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClickUploadJson = (e) => {
    if (operationUtils.checkFileIsJson(e[0].type)) {
      console.log("Upload json...", e);
      const jsonFile = e[0];
      readAndParseJsonFile(jsonFile);
    } else {
      alert("Error! Check for the extension of the file");
      return;
    }
  };

  const handleClickRemoveAll = () => {
    if (markers) {
      operationUtils.removeMarkersFromMap(markersObjects);
      markersObjects.clear();

      setMarkers([]);
      setUploadMarkerArray([]);
      if (route.length > 0) {
        setRoute([]);
      }

      setJsonInterface(JSON.stringify(emptyMapboxJson, undefined, 2));
      parseMarkersFromJson(JSON.stringify(emptyMapboxJson, undefined, 2));
      setDeleteInfo(true);
      setshowAddOperationForm(false);
    } else return;
  };

  const handleClickShowForm = () => {
    !showAddOperationForm
      ? setshowAddOperationForm(true)
      : setshowAddOperationForm(false);
  };

  const handleJsonInterfaceChange = (value) => {
    setJsonInterface(value);
  };

  function readAndParseJsonFile(file) {
    const fileReader = new FileReader();
    fileReader.readAsText(file, "UTF-8");
    fileReader.addEventListener("load", (ev) => {
      const json: any = ev.target?.result;
      parseMarkersFromJson(json);
      setJsonInterface(json);
    });
  }

  function setStartingPointAttributes(markersFromFile) {
    // set Starting Point
    const list = [...initialLatitudeLongitude];
    list[0] = markersFromFile.StartingPoint.lng;
    list[1] = markersFromFile.StartingPoint.lat;
    setinitialLatitudeLongitude(list);

    // set Date
    setDate(markersFromFile.StartingPoint.date);

    // set Time
    setTime(markersFromFile.StartingPoint.time);

    // set Capacity Limit
    const capacityLimit: CapacityLimit[] = [];
    markersFromFile.StartingPoint.capacityLimit.forEach((capacity, index) => {
      capacityLimit[index] = capacity;
    });
    setCapacityLimit(capacityLimit);
  }

  function setEndingPointAttributes(markersFromFile) {
    // set Ending Points
    const list = [...finalLatitudeLongitude];
    list[0] = markersFromFile.EndingPoint.lng;
    list[1] = markersFromFile.EndingPoint.lat;
    setfinalLatitudeLongitude(list);
  }

  function createMarkerPoint(latitude, longitude, label) {
    const el = operationUtils.styleMarker();
    const markerPoint = new mapboxgl.Marker(el)
      .setLngLat([longitude, latitude])
      .setPopup(new mapboxgl.Popup().setHTML(`<h4>${label}</h4>`));
    markersObjects.set(`${latitude}-${longitude}`, markerPoint);
    setUploadMarkerArray((prev) => [...prev, markerPoint]);
  }

  function createMarkersOnMap(markersFromFile) {
    markersFromFile.Markers.forEach((marker) => {
      if (
        operationUtils.checkMarkerObjectExist(
          markersObjects,
          marker.lat,
          marker.lng
        )
      ) {
        createMarkerPoint(marker.lat, marker.lng, marker.label);

        setMarkers((prev) => [
          ...prev,
          {
            label: marker.label,
            type: marker.type,
            lat: marker.lat,
            lng: marker.lng,
            area_value: marker.area_value,
          },
        ]);
      }
    });
  }

  function parseMarkersFromJson(jsonFile) {
    try {
      const markersFromFile = JSON.parse(jsonFile);

      // set everything to empty
      setMarkers([]);
      setUploadMarkerArray([]);

      operationUtils.removeMarkersFromMap(markersObjects);

      markersObjects.clear();

      if (route.length > 0) {
        setRoute([]);
      }

      if (operationUtils.checkStartingPointExist(markersFromFile)) {
        setStartingPointAttributes(markersFromFile);
      }

      if (operationUtils.checkEndingPointExist(markersFromFile)) {
        setEndingPointAttributes(markersFromFile);
      }

      createMarkersOnMap(markersFromFile);
      setDeleteInfo(true);
    } catch (err) {
      console.log("Error while parsing the json");
      throw new Error("Parsing error");
    }
  }

  function getDataFromMapboxComponent(val) {
    setMapLoaded(val);
  }

  function extractLatitudeLongitudeFromMarkers(Markers) {
    const latLng = Markers.map((direction) => {
      return [direction[0], direction[1]];
    });

    setRoute(latLng);

    return latLng;
  }

  return (
    <div className="container-fluid">
      <div className="row">
        {/* Header And Buttons */}
        <div className="col-12">
          <Header />
          <OperationButtons
            mapLoaded={mapLoaded}
            markersObjects={markersObjects}
            showAddOperationForm={showAddOperationForm}
            capacityLimit={capacityLimit}
            handleClickStart={handleClickStart}
            handleClickShowForm={handleClickShowForm}
            handleClickRemoveAll={handleClickRemoveAll}
            handleClickUploadJson={handleClickUploadJson}
            setUploadMarkerArray={setUploadMarkerArray}
            setMarkers={setMarkers}
          />
        </div>
      </div>

      <div className="row">
        {/* Json Text Area */}
        <div className="col-4">
          <CodeMirror
            value={jsonInterface}
            extensions={[json()]}
            onChange={handleJsonInterfaceChange}
          />
        </div>

        <div className="col-8">
          {/* Mapbox map */}
          <div className="row">
            <Mapbox
              initialCapacities={capacityLimit}
              startingPoint={initialLatitudeLongitude}
              endingPoint={finalLatitudeLongitude}
              markerArray={uploadMarkerArray}
              markersObjects={markersObjects}
              route={route}
              routeInfo={routeInfo}
              isLoading={isLoading}
              capacitiesArray={capacitiesArray}
              jsonInterface={jsonInterface}
              deleteInfo={deleteInfo}
              sendDataToOperationsComponent={getDataFromMapboxComponent}
              parseMarkersFromJson={parseMarkersFromJson}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Operations;
