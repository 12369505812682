import circle from "../assets/images/circle-xxl.png";

export function getMaxTripTime(mapboxJson) {
  return mapboxJson.StartingPoint.capacityLimit.find(
    (el) => el.id === "operationTime"
  )?.quantity;
}

export function getMarkerCapacities(capacityLimitJson){
  let capacityLimitKeys = capacityLimitJson.map((key) => key.id);

    const result = capacityLimitKeys.map((capacity) => {
      const name = capacity;
      const quantity = 0;
      return { name, quantity };
    });
    return result;
}

export function calculateReachableMarkers(
  maxTripTimeMinutes,
  legs,
  operation_times
) {
  let counterMarker = 0;

  legs.forEach((leg, index) => {
    if (operation_times.length < 1) {
      maxTripTimeMinutes = calculateMaxTripTimeMinutes(
        maxTripTimeMinutes,
        leg,
        index
      );
      if (checkMaxTripTimeMinutesValue(maxTripTimeMinutes)) {
        return counterMarker;
      } else {
        counterMarker++;
      }
    } else {
      if (index === 0) {
        maxTripTimeMinutes = calculateMaxTripTimeMinutes(
          maxTripTimeMinutes,
          leg,
          index
        );
        if (checkMaxTripTimeMinutesValue(maxTripTimeMinutes)) {
          return counterMarker;
        } else {
          counterMarker++;
        }
      } else {
        maxTripTimeMinutes = calculateMaxTripTimeMinutes(
          maxTripTimeMinutes,
          leg,
          index,
          operation_times
        );
        if (checkMaxTripTimeMinutesValue(maxTripTimeMinutes)) {
          return counterMarker;
        } else {
          counterMarker++;
        }
      }
    }
  });

  if (counterMarker !== 0) {
    return counterMarker - 1;
  } else return counterMarker;
}

export function calculateMaxTripTimeMinutes(
  maxTripTimeMinutes,
  leg,
  index,
  operation_times?
) {
  if (operation_times) {
    return (maxTripTimeMinutes =
      maxTripTimeMinutes -
      Math.floor(leg.duration / 60) -
      operation_times[index - 1]);
  } else
    return (maxTripTimeMinutes =
      maxTripTimeMinutes - Math.floor(leg.duration / 60));
}

export function checkMaxTripTimeMinutesValue(maxTripTimeMinutes) {
  if (maxTripTimeMinutes < 0) {
    return true;
  } else return false;
}

export function checkStartingPointExist(markersFromFile) {
  return markersFromFile.StartingPoint ? true : false;
}

export function checkEndingPointExist(markersFromFile) {
  return markersFromFile.EndingPoint ? true : false;
}

export function checkFileIsJson(fileType) {
  return fileType === "application/json" ? true : false;
}

export function checkMarkerObjectExist(markersObjects, lat, lng) {
  return !markersObjects.get(`${lat}-${lng}`) ? true : false;
}

export function styleMarker() {
  const el = document.createElement("div");
  el.style.backgroundImage = `url(${circle})`;
  el.style.backgroundSize = "100%";
  el.style.width = "15px";
  el.style.height = "15px";

  return el;
}

export function getCapacityObject(markerCapacities) {
  const capacities = {};
  
  for (const key of markerCapacities) {
    capacities[key.name] = key.quantity;
  }

  return capacities;
}

export function removeMarkersFromMap(markersObjects: Map<String, mapboxgl.Marker>) {
  markersObjects.forEach((value) => {
    value.remove();
  });
}

