// React
import { useRef } from "react";

// Dependencies
import { jsPDF } from "jspdf";

// Utils
import * as renderInfoUtils from "../utils/renderInfoUtils";

export const RenderInfo = (props) => {
  /**Attributes */
  const printRef = useRef(null);

  /**Functions */
  const handleDownloadPdf = async () => {
    const element: any = printRef.current;
    const doc = new jsPDF();
    var img = new Image();
    img.src = require("../assets/images/logo-switch.png");
    img.onload = function () {
      doc.addImage(img, "png", 10, 2, 0, 0);
      doc.text("Priority Operation Manager", 10, 30);

      doc.html(props.mapCanva, {
        callback: function (doc) {
          doc.html(element, {
            callback: function (doc) {
              doc.save("operations_path.pdf");
            },
            width: 100,
            windowWidth: 500,
            x: 11,
            y: 120,
          });
        },
        width: 10,
        windowWidth: 50,
        x: 10,
        y: 40,
      });
    };
  };

  function downloadablePdf() {
    return (
      <div className="col">
        <button
          type="button"
          className="btn btn-outline-danger"
          onClick={handleDownloadPdf}
          style={{ justifyContent: "center" }}
        >
          Download Informations in PDF
        </button>
      </div>
    );
  }

  function renderInfo() {
    if (props.routeInfo) {
      const arrivalTime: Date = new Date(
        props.routeInfo.markersInfo.departure_time
      );
      const departureTime: Date = new Date(
        props.routeInfo.markersInfo.departure_time
      );
      const operationTimes: number[] = props.routeInfo.markersInfo.time;
      const mapboxLegs = props.routeInfo.info.legs;
      const initialCapacities = props.initialCapacities;
      const operationLabels = props.routeInfo.markersInfo.operationType;

      return (
        <div className="col-7">
          <hr />
          {downloadablePdf()}

          <div ref={printRef}>
            <hr />

            {renderInfoUtils.startingInformations(mapboxLegs, departureTime, initialCapacities)}

            <hr />

            {renderInfoUtils.renderLegs(
              mapboxLegs,
              operationTimes,
              arrivalTime,
              operationLabels,
              props.capacitiesArray
            )}
          </div>
        </div>
      );
    } else return <div>No info to show about your trip</div>;
  }

  return <div className="d-flex flex-row">{renderInfo()}</div>;
};
