// Resources
import redMarker from "../assets/images/mapboxRedMarker.png";

export function updateStartingPoint(map, coords) {
  if (map) {
    if (map.getSource("StartingPoints")) {
      map.removeLayer("StartPoints");
      map.removeSource("StartingPoints");
    }

    map.addSource("StartingPoints", {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [coords[0], coords[1]],
            },
            properties: {
              title: "Start",
              description: "Starting Point",
            },
          },
        ],
      },
    });

    map.addLayer({
      id: "StartPoints",
      type: "symbol",
      source: "StartingPoints",
      layout: {
        "icon-image": "car-15",
        "icon-size": 2.5,
      },
    });

    return map;
  } else return;
}

export function updateEndingPoint(map, coords) {
  if (map) {
    if (map.getSource("EndingPoints")) {
      map.removeLayer("EndPoints");
      map.removeSource("EndingPoints");
      map.removeImage("redMark");
    }

    map.loadImage(redMarker, (error, image: any) => {
      if (error) throw error;

      map.addSource("EndingPoints", {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: [
            {
              type: "Feature",
              geometry: {
                type: "Point",
                coordinates: [coords[0], coords[1]],
              },
              properties: {
                title: "End",
                description: "Ending Point",
              },
            },
          ],
        },
      });

      map.addLayer({
        id: "EndPoints",
        type: "symbol",
        source: "EndingPoints",
        layout: {
          "icon-image": "redMark",
          "icon-size": 1.5,
        },
      });

      map.addImage("redMark", image);
    });
    return map;
  }else return;
}

export function createMarkerOnMap(map, addMarker){
  if (map && addMarker) {
    console.log("Inside createPointOnMap in mapbox", addMarker);
    const marker = addMarker;
    marker.addTo(map);
  }
}

export function removeMarkerOnMap(map, removeMarker){
  if (map && removeMarker) {
    console.log("Inside removePointOnMap in mapbox");
    const marker = removeMarker;
    marker.remove();
  }
};

export function drawRouteOnMap(map, coords){
  console.log(
    "Inside drawDirectionsOnMap in mapbox, route is full",
    coords
  );
  addRoute(map, coords);
}

function addRoute(map, coords){
  if (map) {
    if (map.getSource("route")) {
      const source: mapboxgl.GeoJSONSource = map.getSource(
        "route"
      ) as mapboxgl.GeoJSONSource;
      source.setData({
        type: "Feature",
        properties: {},
        geometry: {
          type: "LineString",
          coordinates: coords,
        },
      });
    } else {
      map.addLayer({
        id: "route",
        type: "line",
        source: {
          type: "geojson",
          data: {
            type: "Feature",
            properties: {},
            geometry: {
              type: "LineString",
              coordinates: coords,
            },
          },
        },
        layout: {
          "line-join": "round",
          "line-cap": "round",
        },
        paint: {
          "line-color": "#3887be",
          "line-width": 5,
          "line-opacity": 0.75,
        },
      });
    }
    addPoints(map, coords);
    addDirectionality(map);
  } else return;
}

function addPoints(map, coords) {
  if (map) {
    if (map.getSource("point")) {
      const source: mapboxgl.GeoJSONSource = map.getSource(
        "point"
      ) as mapboxgl.GeoJSONSource;
      source.setData({
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            properties: {},
            geometry: {
              type: "Point",
              coordinates: coords,
            },
          },
        ],
      });
    } else {
      map.addLayer({
        id: "point",
        type: "circle",
        source: {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: [
              {
                type: "Feature",
                properties: {},
                geometry: {
                  type: "Point",
                  coordinates: coords,
                },
              },
            ],
          },
        },
        paint: {
          "circle-radius": 10,
          "circle-color": "#3887be",
        },
      });
    }
  } else return;
}

function addDirectionality(map) {
  if (map) {
    if (map.getLayer("routearrows")) {
      map.removeLayer("routearrows");
    }

    map.addLayer(
      {
        id: "routearrows",
        type: "symbol",
        source: "route",
        layout: {
          "symbol-placement": "line",
          "text-field": "▶",
          "text-size": ["interpolate", ["linear"], ["zoom"], 12, 24, 22, 60],
          "symbol-spacing": [
            "interpolate",
            ["linear"],
            ["zoom"],
            12,
            30,
            22,
            160,
          ],
          "text-keep-upright": false,
        },
        paint: {
          "text-color": "#3887be",
          "text-halo-color": "hsl(55, 11%, 96%)",
          "text-halo-width": 3,
        },
      },
      "waterway-label"
    );
  } else return;
}

export function routeUnderMarkers(map) {
  if (map) {
    map.moveLayer("route", "StartPoints");
  }
}

export function removeRouteFromMap(map) {
  if (map) {
    console.log("Inside drawDirectionsOnMap in mapbox, route is empty");
    if (map.getLayer("point")) {
      map.removeLayer("point");
    }
    if (map.getSource("point")) {
      map.removeSource("point");
    }
    if (map.getLayer("routearrows")) {
      map.removeLayer("routearrows");
    }
    if (map.getSource("route")) {
      const source: mapboxgl.GeoJSONSource = map.getSource(
        "route"
      ) as mapboxgl.GeoJSONSource;
      source.setData({
        type: "Feature",
        properties: {},
        geometry: {
          type: "LineString",
          coordinates: [],
        },
      });
    }
  }
}

export function calculateTotalTripDuration(operation_times, routeInfo) {
  let totalTripDuration;

  if (operation_times.length < 1) {
    let total_duration = routeInfo.info.duration;
    totalTripDuration = Math.floor(total_duration / 60);
  } else {
    let total_duration = routeInfo.info.duration;
    totalTripDuration = Math.floor(
      total_duration / 60 -
        operation_times.reduce(
          (previousTime, currentTime) => previousTime + currentTime
        )
    );
  }

  return totalTripDuration;
}

export function checkPossibleRendering(routeInfo, capacitiesArray, loadingSpinner, deleteInfo) {
  return (routeInfo || capacitiesArray.length >= 1) &&
    !loadingSpinner &&
    !deleteInfo
    ? true
    : false;
}

export function showRouteInfo(routeInfo) {
  const departureTime = routeInfo.markersInfo.departure_time.substring(
    11,
    16
  );
  const operationTimes = routeInfo.markersInfo.time;
  const totalDistance = routeInfo.info.distance;
  const totalTripDuration = calculateTotalTripDuration(
    operationTimes,
    routeInfo
  );

  return (
    <div className="info">
      <b>Departure time: </b> {departureTime}
      <br />
      <b>Total trip duration:</b> {totalTripDuration} min
      <br />
      <b>Distance:</b> {Math.floor(totalDistance / 1000)} km
      <br />
    </div>
  );
} 