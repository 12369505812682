// React
import { useEffect, useRef, useState } from "react";

// Components
import { RenderInfo } from "./RenderInfo";

// Mapbox
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";

// Interfaces
import { RouteInfo } from "../interfaces/RouteInfo";

// Utils
import * as mapboxUtils from "../utils/mapboxUtils";

/* Amplify Params - DO NOT EDIT */
import ReactMapGL from "react-map-gl";
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

export const Mapbox = (props) => {
  /**Attributes */

  const [map, setMap] = useState<mapboxgl.Map>();
  const [routeInfo, setRouteInfo] = useState<RouteInfo>();
  const [mapCanva, setMapCanva] = useState<HTMLElement>();
  const [loadingSpinner, setLoadingSpinner] = useState(null);
  const [deleteInfo, setDeleteInfo] = useState(null);
  const [mapLoaded, setMapLoaded] = useState(false);
  const mapNode = useRef(null);

  /**Functions */
  
  // creates and set map
  useEffect(() => {
    const node = mapNode.current;
    if (node === null) return;

    const mapboxMap = new mapboxgl.Map({
      container: node,
      accessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
      style: "mapbox://styles/mapbox/light-v10",
      center: [12.49, 41.9],
      zoom: 10,
      preserveDrawingBuffer: true,
    });

    mapboxMap.on("load", () => {
      setMapLoaded(true);
      setMapCanva(mapboxMap.getCanvasContainer());
      props.sendDataToOperationsComponent(true);
      mapboxMap.resize();
    });

    setMap(mapboxMap);
    console.log("Map has been loaded!");

    return () => {
      mapboxMap.remove();
    };
  }, []);

  // update starting points
  useEffect(() => {
    console.log("Inside updating starting point in mapbox ");
    mapboxUtils.updateStartingPoint(map, props.startingPoint);
  }, [props.startingPoint]);

  // update ending points
  useEffect(() => {
    console.log("Inside updating ending point in mapbox ");
    mapboxUtils.updateEndingPoint(map, props.endingPoint);
  }, [props.endingPoint]);

  // add/remove route on map
  useEffect(() => {
    if (map && props.route.length > 0) {
      mapboxUtils.drawRouteOnMap(map, props.route);
      mapboxUtils.routeUnderMarkers(map);
    } else {
      mapboxUtils.removeRouteFromMap(map);
    }
  }, [props.route]);

  // Add all markers to map
  useEffect(() => {
    if (map && props.markerArray.length !== 0) {
      console.log("Inside markerArray in mapbox", props.markerArray);
      const marker = props.markerArray;
      marker.forEach((el) => {
        el.addTo(map);
      });
    } else return;
  }, [props.markerArray]);

  // add route info to page
  useEffect(() => {
    setRouteInfo(props.routeInfo);
  }, [props.routeInfo]);

  // set loading spinner
  useEffect(() => {
    setLoadingSpinner(props.isLoading);
  }, [props.isLoading]);

  // show or not the info about trip
  useEffect(() => {
    setDeleteInfo(props.deleteInfo);
  }, [props.deleteInfo]);

  function infoRendering() {
    return (
      <div className="col">
        {mapLoaded ? 
        (
          <div className="row-2">
            <button
              className="btn btn-outline-success"
              type="button"
              onClick={() => props.parseMarkersFromJson(props.jsonInterface)}
            >
              Update Map from Json
            </button>
          </div>
        ) : (
          <div
            className="spinner-border text-primary"
            role="status"
            style={{ marginLeft: "15px" }}
          />
        )}

        {mapboxUtils.checkPossibleRendering(props.routeInfo, props.capacitiesArray, loadingSpinner, deleteInfo) ? (
          <RenderInfo
            routeInfo={props.routeInfo}
            capacitiesArray={props.capacitiesArray}
            initialCapacities={props.initialCapacities}
            mapCanva={mapCanva}
          />
        ) : loadingSpinner ? (
          <div
            className="col-7 spinner-border text-info"
            role="status"
            style={{ marginLeft: "15px", marginTop: "10px" }}
          />
        ) : (
          <div
            className="col-7 d-flex"
            style={{ marginLeft: "15px", marginTop: "10px" }}
          >
            No info trip to show
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="col">
      <div className="row">
        {/* Mapbox Map */}
        <div
          className="container col-8"
          ref={mapNode}
          style={{
            width: "1000vh",
            height: "50vh",
          }}
        >
          {/* Info About trip like: Departure time, total duration, distance */}
          {routeInfo && !deleteInfo ? mapboxUtils.showRouteInfo(props.routeInfo) : <></>}
        </div>
      </div>

      <div className="row">
        <hr />
        {/* Info About travel from marker to marker*/}
        {infoRendering()}
      </div>
    </div>
  );
};
