// Images
import playButton from "../assets/images/play.png";
import binButton from "../assets/images/bin.png";

// Mui
import {
  Button,
  FormControl,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

// React
import { useState } from "react";

// Utils
import * as operationUtils from "../utils/operationUtils";

// Mapbox
import mapboxgl from "mapbox-gl";

export const OperationButtons = (props) => {
  /**Attributes */

  // Markers example informations used to create the operation
  const [markerLabel, setMarkerLabel] = useState<string>("Example Operation");
  const [markerLatitude, setMarkerLatitude] = useState<number>(41.8725);
  const [markerLongitude, setMarkerLongitude] = useState<number>(12.4357);
  const [markerOperationType] = useState<string[]>([
    "Missing Item",
    "Manteinance",
    "Battery Swap",
  ]);
  const [markerOperationTypeValue, setMarkerOperationTypeValue] =
    useState<number>(6);
  const [markerAreaValue, setMarkerAreaValue] = useState<number>(9);
  const [selectedOperationType, setSelectedOperationType] = useState<string>(
    markerOperationType[0]
  );

  // Capacity associated to a single marker : {id, name, quant}
  const [markerCapacities, setMarkerCapacities] = useState(
    operationUtils.getMarkerCapacities(props.capacityLimit)
  );

  /**Functions */

  const handleMarkerLabelChange = (e) => {
    const { name } = e.target.value;
    setMarkerLabel(name);
  };

  const handleMarkerLatitudeChange = (e) => {
    setMarkerLatitude(Number(e.target.value));
  };

  const handleMarkerLongitudeChange = (e) => {
    setMarkerLongitude(Number(e.target.value));
  };

  const handleMarkerAreaValueChange = (e) => {
    setMarkerAreaValue(Number(e.target.value));
  };

  const handleMarkerOperationTypeChange = (e) => {
    setSelectedOperationType(e.target.value);
  };

  const handleMarkerOperationTypeValueChange = (e) => {
    setMarkerOperationTypeValue(Number(e.target.value));
  };

  const handleMarkerCapacityChange = (e, index) => {
    const { value } = e.target;
    const list = [...markerCapacities];
    list[index]["quantity"] = Number(value);
    setMarkerCapacities(list);
  };

  function createMarkerPoint(latitude, longitude, label) {
    const el = operationUtils.styleMarker();
    const markerPoint = new mapboxgl.Marker(el)
      .setLngLat([longitude, latitude])
      .setPopup(new mapboxgl.Popup().setHTML(`<h4>${label}</h4>`));
    props.markersObjects.set(`${latitude}-${longitude}`, markerPoint);
    props.setUploadMarkerArray((prev) => [...prev, markerPoint]);
  }

  function getOperation(selectedOperationType) {
    return selectedOperationType === markerOperationType[0]
      ? "missing_item"
      : selectedOperationType === markerOperationType[1]
      ? "manteinance"
      : "battery_swap";
  }

  const addMarkerUsingForm = (e) => {
    console.log("Adding new Marker using the form ");
    if (
      operationUtils.checkMarkerObjectExist(
        props.markersObjects,
        markerLatitude,
        markerLongitude
      )
    ) {
      createMarkerPoint(markerLatitude, markerLongitude, markerLabel);

      const capacities = operationUtils.getCapacityObject(markerCapacities);
      const operation = getOperation(selectedOperationType);

      const markerType = {
        index: 0, // needs to be changed
        op: operation,
        text: selectedOperationType,
        value: markerOperationTypeValue,
        capacities: capacities,
      };

      props.setMarkers((prev) => [
        ...prev,
        {
          label: markerLabel,
          type: markerType,
          lat: markerLatitude,
          lng: markerLongitude,
          area_value: markerAreaValue,
        },
      ]);
    } else {
      alert("This marker already exist!");
    }
  };

  const addMarkerForm = () => {
    return (
      <div className="col">
        <FormControl>
          <div className="col">
            <TextField
              margin="dense"
              sx={{ marginLeft: "5px" }}
              id="outlined-label"
              label="Label"
              variant="outlined"
              value={markerLabel}
              onChange={(e) => handleMarkerLabelChange(e)}
            />

            <TextField
              margin="dense"
              sx={{ marginLeft: "5px" }}
              id="outlined-latitude"
              label="Latitude"
              variant="outlined"
              value={markerLatitude}
              type="number"
              onChange={(e) => handleMarkerLatitudeChange(e)}
            />

            <TextField
              margin="dense"
              sx={{ marginLeft: "5px" }}
              id="outlined-longitude"
              label="Longitude"
              variant="outlined"
              value={markerLongitude}
              type="number"
              onChange={(e) => handleMarkerLongitudeChange(e)}
            />
            <TextField
              margin="dense"
              sx={{ marginLeft: "5px" }}
              id="outlined-areaValue"
              label="Area Value"
              variant="outlined"
              value={markerAreaValue}
              type="text"
              onChange={(e) => handleMarkerAreaValueChange(e)}
            />
          </div>

          <div className="col">
            <Select
              margin="dense"
              sx={{ marginLeft: "5px" }}
              id="outlined-operationTypeValue"
              variant="outlined"
              type="text"
              label="Operation Type"
              onChange={(e) => handleMarkerOperationTypeChange(e)}
              value={selectedOperationType}
            >
              {markerOperationType.map((operation, index) => {
                return (
                  <MenuItem key={index} value={operation}>
                    {operation}
                  </MenuItem>
                );
              })}
            </Select>

            <TextField
              margin="dense"
              sx={{ marginLeft: "5px" }}
              id="outlined-operationTypeValue"
              label="Operation Type Value"
              variant="outlined"
              value={markerOperationTypeValue}
              type="number"
              onChange={(e) => handleMarkerOperationTypeValueChange(e)}
            />
          </div>

          <div className="col">
            {markerCapacities.map((capacity, index) => (
              <TextField
                margin="dense"
                sx={{ marginLeft: "5px" }}
                id="outlined-quantity"
                name="quantity"
                key={index}
                label={capacity.name}
                value={capacity.quantity}
                type="number"
                variant="outlined"
                onChange={(e) => handleMarkerCapacityChange(e, index)}
              >
                {capacity.name}
              </TextField>
            ))}
          </div>

          <div className="col d-flex justify-content-center">
            <Button
              sx={{ margin: "10px" }}
              variant="outlined"
              onClick={(e) => addMarkerUsingForm(e)}
            >
              Add marker to Map
            </Button>
          </div>
        </FormControl>
      </div>
    );
  };

  return (
    <div className="row buttons-top justify-content-start">
      {props.mapLoaded ? (
        <div className="d-flex flex-row">
          <button
            className="btn btn-outline-primary btn-lg"
            style={{ marginRight: "1em" }}
            onClick={() => props.handleClickStart(1)}
          >
            Shortest
            <img src={playButton} alt="" style={{ width: "1em" }} />
          </button>

          <button
            className="btn btn-outline-primary btn-lg"
            style={{ marginRight: "1em" }}
            onClick={() => props.handleClickStart(2)}
          >
            Priority
            <img src={playButton} alt="" style={{ width: "1em" }} />
          </button>

          <button
            className="btn btn-outline-success btn-lg"
            style={{ marginRight: "1em" }}
            onClick={props.handleClickShowForm}
          >
            Add using Form
          </button>

          <button
            className="btn btn-outline-danger btn-lg"
            style={{ marginRight: "1em" }}
            onClick={props.handleClickRemoveAll}
          >
            Delete
            <img src={binButton} alt="" style={{ width: "1em" }} />
          </button>

          <div>
            <label htmlFor="files" className="btn btn-outline-secondary">
              Choose a file
            </label>
            <input
              className="btn btn-outline-secondary"
              id="files"
              type="file"
              style={{
                marginTop: "12px",
                marginBottom: "12px",
                visibility: "hidden",
              }}
              onChange={(e) => props.handleClickUploadJson(e.target.files)}
            />
          </div>
        </div>
      ) : (
        <div
          className="spinner-border text-primary"
          role="status"
          style={{ marginLeft: "15px" }}
        />
      )}

      {props.showAddOperationForm ? addMarkerForm() : <></>}
    </div>
  );
};
