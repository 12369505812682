export function startingInformations(mapboxLegs, departureTime, initialCapacities) {
  return (
    <div>
      <b>Starting point from </b>{" "}
      {mapboxLegs[0].steps.find((el) => el.name.length > 0).name} at{" "}
      {departureTime.toString().substring(16, 21)}
      <br />
      <b>Starting Capacities: </b>
      {initialCapacities.map((el, index) => {
        return (
          <ul key={index}>
            <li>
              {el.name}: {el.quantity}
            </li>
          </ul>
        );
      })}
    </div>
  );
}

export function renderLegs(
  mapboxLegs,
  operationTimes,
  arrivalTime,
  operationLabels,
  capacitiesArray
) {
  return lessThanOneLeg(operationTimes)
    ? justOneLeg(mapboxLegs[0], arrivalTime)
    : mapboxLegs.map((leg, index) => {
        return moreThanOneLeg(
          leg,
          arrivalTime,
          mapboxLegs,
          operationTimes,
          operationLabels,
          index,
          capacitiesArray
        );
      });
}

function moreThanOneLeg(
  leg,
  arrivalTime,
  mapboxLegs,
  operationTimes,
  operationLabels,
  index,
  capacitiesArray
) {
  return (
    <div key={index}>
      {/* Road where to stop and duration time to arrive*/}
      <b>Stop: </b> {leg.steps.findLast((el) => el.name.length > 0).name}{" "}
      <b> in </b>
      {Math.floor(leg.duration / 60)} min
      <br />
      {/* Arrival Time */}
      <b>You will arrive here at </b>{" "}
      {addMinutes(Math.floor(leg.duration / 60), arrivalTime)
        .toString()
        .substring(16, 21)}
      <br />
      {lastIndex(index, mapboxLegs) ? (
        <div>
          <b>Operation time: </b> 0mins.
          <br />
          <b>You have arrived at your destination. </b>
        </div>
      ) : (
        <div>
          {/* Operational time */}
          <b>Operation time: </b>
          {Math.abs(operationTimes[index])}mins.
          <br />
          {/* Add time of the operation to the arrival time */}
          <b>You Should start again at </b>
          {addMinutes(Math.abs(operationTimes[index]), arrivalTime)
            .toString()
            .substring(16, 21)}
          <br />
          {/* Identify which marker is referring to */}
          <b>Current Marker is </b>
          {operationLabels[index].label}
          <br />
          {/* Returns actual capacities */}
          {getCapacities(capacitiesArray, index)}
        </div>
      )}
      <br />
      <hr />
    </div>
  );
}

function lessThanOneLeg(operationTimes) {
  return operationTimes.length < 1 ? true : false;
}

function justOneLeg(leg, arrivalTime) {
  return (
    <div>
      <b>Stop: </b> {leg.steps.findLast((el) => el.name.length > 0).name}{" "}
      <b> in </b> {Math.floor(leg.duration / 60)} min
      <br />
      <b>You will arrive here at </b>
      {addMinutes(Math.floor(leg.duration / 60), arrivalTime)
        .toString()
        .substring(16, 21)}
      <br />
      <b>No operation must be executed, just trip </b>
      <br />
      <hr />
    </div>
  );
}

function addMinutes(numOfMinutes, date) {
  date.setMinutes(date.getMinutes() + numOfMinutes);
  return date;
}

function lastIndex(index, mapboxLegs) {
  return index === mapboxLegs.length - 1 ? true : false;
}

function getCapacities(capacitiesArray, currentIndex) {
  return(
    <div>
      <b>Remaining Capacities: </b>
      {capacitiesArray[currentIndex].map((el, index) => {
        if (el.quantity <= 0) {
          return (
            <ul key={index} style={{ color: "red" }}>
              <li>
                <b>{el.name}</b> is <b>{el.quantity}</b>
              </li>
            </ul>
          );
        } else {
          return (
            <ul key={index} style={{ color: "green" }}>
              <li>
                <b>{el.name}</b> is <b>{el.quantity}</b>
              </li>
            </ul>
          );
        }
      })
      }
    </div>
  )}