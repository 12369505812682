export const Header = () => {
  return (
      <div className="col-4 header d-flex">
        <div style={{fontSize:"1em", fontFamily: "Verdana", marginTop:"2px"}}>PRIORITY OPERATIONS MANAGER</div>
      </div>
  )
}



